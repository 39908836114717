import { Api } from '@helpers/api';
import { IApiPaginateResponse } from '@helpers/api/types';
import {
  ContestantStatus,
  CreateContestantOptions,
  IContestant,
  IContestantApiResponse,
  IGetContestantsApiResponse,
  IGetSingleContestantApiResponse
} from './types';

const getContestantsApiBase = (contest: string) => `contests/${contest}/contestants`;

/* Contestant API Helpers */
export const getContestantsForContest = async (contest: string, params: { status: string } = { status: ContestantStatus.Approved }, page?: Pick<IApiPaginateResponse, 'page' | 'itemsPerPage'>) => {
  const res: IGetContestantsApiResponse = await Api.get(`${getContestantsApiBase(contest)}${page ? `?itemsPage=${page.page}&itemsPerPage=${page.itemsPerPage || 10}` : ''}`, { params });
  return res;
};

export const getSingleContestant = async (contest: string, id: string) => {
  const res: IGetSingleContestantApiResponse = await Api.get(`${getContestantsApiBase(contest)}/${id}`);
  return res;
};

export const checkContestantStatus = async (contest: string, id: string) => {
  const res: IGetSingleContestantApiResponse = await Api.get(`${getContestantsApiBase(contest)}/${id}/check`);
  return res;
}

export const createContestant = async (contest: string, data: CreateContestantOptions) => {
  const res: IContestantApiResponse = await Api.post(`${getContestantsApiBase(contest)}/`, data);
  return res;
};

export const inviteContestant = async (contest: string, data: CreateContestantOptions) => {
  const res: IContestantApiResponse = await Api.post(`${getContestantsApiBase(contest)}/invite`, data);
  return res;
};

export const updateContestant = async (contest: string, id: string, data: Partial<IContestant>) => {
  const res: IGetSingleContestantApiResponse = await Api.put(`${getContestantsApiBase(contest)}/${id}`, data);
  return res;
};

export const removeContestant = async (contest: string, id: string) => {
  const res: IContestantApiResponse = await Api.delete(`${getContestantsApiBase(contest)}/${id}`);
  return res;
};

export const updateContestantImage = async (contest: string, id: string, file: File) => {
  const data = new FormData();
  data.append('image', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const res: IGetSingleContestantApiResponse = await Api.post(`${getContestantsApiBase(contest)}/${id}/upload`, data, config);
  return res;
};

export const updateContestantMultipleMedia = async (contest: string, id: string, files: FileList) => {
  const data = new FormData();
  for(let file of files) {
    data.append("image", file)
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const res: IGetSingleContestantApiResponse = await Api.post(`${getContestantsApiBase(contest)}/${id}/upload/multiple`, data, config);
  return res;
};
