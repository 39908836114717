import { getAllContests } from "@services/contest";
import { IGetContestsApiResponse } from "@services/contest/types";
import useSWR from "swr";

export const useHostedContests = () => {
  const fetcher = () => getAllContests('author', { page: 1, itemsPerPage: 3 })

  const { data, error } = useSWR(`useHostedContests`, fetcher, {
    refreshInterval: 0,
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  return {
    hostedContests: (data as IGetContestsApiResponse)?.data || [],
    hostedContestsLoading: !data && !error,
    hostedContestsError: error
  }
};
