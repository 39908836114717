import { getSingleContestant } from "@services/contestant";
import { IGetSingleContestantApiResponse } from "@services/contestant/types";
import useSWR from "swr";

export const useSingleContestant = (contest: string, id: string) => {
  const fetcher = (id: string) => getSingleContestant(contest, id)

  const { data, error, mutate } = useSWR(id, fetcher, {
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  return {
    contestant: (data as IGetSingleContestantApiResponse)?.data,
    contestantLoading: !data && !error,
    mutateContestant: (contestant: IGetSingleContestantApiResponse['data']) => mutate({ ...((data as IGetSingleContestantApiResponse) || {}), data: contestant }),
    contestantError: error
  }
};
