import { IUser } from '@services/user/types';
import { Document, IApiResponse } from '@helpers/api/types';
import { IContest } from '@services/contest/types';

export interface CreateContestantOptions {
  user?: string;
  createUserOptions?: {
    name: IUser['name'];
    email: IUser['email'];
  };
  additionalInfo?: IContestant['additionalInfo'];
  status?: IContestant['status'];
}

export enum ContestantStatus {
  Pending = 'pending',
  Review = 'review',
  Approved = 'approved',
  Disqualified = 'disqualified',
  Paid = 'paid',
  Rejected = "rejected"
}

export interface IContestant extends Document {
  author: {
    _id: IUser['_id'];
    avatar: IUser["avatar"];
    name: IUser["name"];
    role: IUser["role"]
  };
  user: IUser;
  image?: string;
  media: string[];
  name?: string;
  slug: string;
  contest: IContest['_id'];
  additionalInfo: any;
  status: ContestantStatus;
}

export interface IContestantApiResponse extends IApiResponse {
  data: IContestant;
}

export interface IContestantsApiResponse extends IApiResponse {
  data: IContestant[]
}

export interface IGetSingleContestantApiResponse extends IApiResponse {
  data: IContestant & {
    /** Total number of votes for this contestant */
    nVotes: number;
    /** Total votes for this contestant by the logged in user */
    userVotes: number;
    /** Total votes for this contest by the logged in user */
    userTotalVotes: number;
    /** Date/time of the last vote casted by logged in user for this contestant */
    lastOwnVoteAt?: string;
    /** No. of consecutive votes by logged in user for this contestant in the last 24hours */
    seriesVoteCount?: number;
    /** Total number of contestants for this contest */
    nContestants: number;
    /** Current position of this contestant in this contest */
    position: number;
  };
}

export interface IGetContestantsApiResponse extends IApiResponse {
  data: (IContestant & {
    /** Total number of votes for this contestant */
    nVotes: number;
    /** Total votes for this contestant by the logged in user */
    userVotes: number;
    /** Total votes for this contest by the logged in user */
    userTotalVotes: number;
    /** Date/time of the last vote casted by logged in user for this contestant */
    lastOwnVoteAt?: string;
    /** No. of consecutive votes by logged in user for this contestant in the last 24hours */
    seriesVoteCount?: number;
  })[];
}
