import { getVotesForAuthor } from "@services/vote";
import { IGetVotesForAuthorApiResponse } from "@services/vote/types";
import useSWR from "swr";

export const useContestantsByVoter = (voter: string) => {
  // key is passed as 'me'
  const fetcher = (key: string) => getVotesForAuthor(key.split('__')[1])

  const { data, error } = useSWR(`votes/author/me__${voter}`, fetcher, {
    refreshInterval: 0,
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  return {
    contestants: (data as IGetVotesForAuthorApiResponse)?.data || [],
    contestantsLoading: !data && !error,
    contestantsError: error
  }
};
