import { getAllContests } from "@services/contest";
import { IGetContestsApiResponse } from "@services/contest/types";
import useSWR from "swr";

export const useParticipatingContests = () => {
  const fetcher = () => getAllContests('contestant', { page: 1, itemsPerPage: 3 })

  const { data, error } = useSWR(`useParticipatingContests`, fetcher, {
    refreshInterval: 0,
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  return {
    participatingContests: (data as IGetContestsApiResponse)?.data || [],
    participatingContestsLoading: !data && !error,
    participatingContestsError: error
  }
};
