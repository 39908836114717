import { getAllContests } from "@services/contest";
import { IGetContestsApiResponse } from "@services/contest/types";
import { useState, useEffect } from "react";
import useSWRInfinite from "swr/infinite";
import { IApiPaginateResponse } from "@helpers/api/types";

interface UsePaginatedContestsProps {
  page?: number;
  itemsPerPage?: number;
  param?: 'author' | 'contestant';
}

export const usePaginatedContests = ({ page, itemsPerPage, param }: UsePaginatedContestsProps = {}) => {
  const [contests, setContests] = useState<IGetContestsApiResponse['data']>([]);
  const [constestsLoading, setContestsLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const getKey = (pageIndex: number) => `{"key":"getContests","page":${page || pageIndex + 1},"itemsPerPage":${itemsPerPage || 10}}`;

  const fetcher = (opts: string) => {
    const { page, itemsPerPage } = JSON.parse(opts);
    return getAllContests(param, { page, itemsPerPage });
  }

  const { data, size, setSize: setPage, mutate } = useSWRInfinite(getKey, fetcher, {
    initialSize: 1,
    // revalidateOnFocus: false,
    // revalidateOnMount: false,
    // revalidateOnReconnect: false,
    // refreshWhenOffline: false,
    // refreshWhenHidden: false,
    refreshInterval: 0
  });

  useEffect(() => {
    if (data) {
      let newResource: IGetContestsApiResponse = data[data.length - 1]
      const meta = newResource.meta as IApiPaginateResponse;
      setContests([
        ...contests,
        ...data.reduce((acc, next) => {
          return ([...acc, ...next.data.filter(d => acc.findIndex(c => c._id === d._id) < 0)])
        }, data[0].data)
        .filter(d => contests.findIndex(c => c._id === d._id) < 0)
      ])

      setContestsLoading(false);
      if (meta) {
        setHasMore(meta.page < meta.pages);
      }
    } else {
      setContestsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    contests,
    constestsLoading,
    hasMore,
    page: size,
    mutate,
    setPage
  }
}