import { getSingleContest } from "@services/contest";
import { IGetSingleContestApiResponse } from "@services/contest/types";
import useSWR from "swr";

export const useSingleContest = (id: string) => {
  const fetcher = (id: string) => getSingleContest(id)

  const { data, error, mutate } = useSWR(id, fetcher, {
    refreshInterval: 0,
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  return {
    contest: (data as IGetSingleContestApiResponse)?.data,
    contestLoading: !data && !error,
    mutateContest: ((contest: IGetSingleContestApiResponse["data"]) => mutate({ ...(data as IGetSingleContestApiResponse || {}), data: contest })),
    contestError: error
  };
};
