import { getContestantsForContest } from "@services/contestant";
import { ContestantStatus, IGetContestantsApiResponse, IGetSingleContestantApiResponse } from "@services/contestant/types";
import useSWR from "swr";

interface UseContestantsOptions {
  params: { status: string }
  allowFetch?: boolean
}

export const useContestants = (contest: string, options: UseContestantsOptions = { params: { status: ContestantStatus.Approved } }) => {
  const fetcher = (contest: string, params: { status: string }) => getContestantsForContest(contest.split('__')[1], params)
  const allowFetch = options.allowFetch ?? true

  const { data, error, mutate } = useSWR(allowFetch ? [`contestants__${contest}`, options.params] : null, fetcher, {
    refreshInterval: 0,
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  return {
    mutateContestants: (contestant: IGetSingleContestantApiResponse["data"]) => mutate({ ...((data as IGetContestantsApiResponse) || {}), data: { ...data?.data.map((c) => c._id === contestant._id ? contestant : c) as IGetContestantsApiResponse["data"] } }),
    contestants: (data as IGetContestantsApiResponse)?.data || [],
    contestantsLoading: !data && !error,
    contestantsError: error
  }
};
