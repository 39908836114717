import { getVotesForContest } from "@services/vote";
import { IGetVotesForContestApiResponse } from "@services/vote/types";
import useSWR from "swr";

export const useLeaderboard = (contest: string, showLeaderboard = true) => {
  const fetcher = (contest: string) => showLeaderboard // only fetch data if leaderboard is showing
    ? getVotesForContest(contest.split('__')[1])
    : Promise.resolve<IGetVotesForContestApiResponse>({
      status: 'success',
      code: '',
      message: '',
      data: { contestants: [], totalVotes: 0 }
    });

  const { data, error, mutate } = useSWR(`votes__${contest}`, fetcher, {
    refreshInterval: 0,
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  return {
    leaderboard: (data as IGetVotesForContestApiResponse)?.data || [],
    leaderboardLoading: !data && !error,
    leaderboardError: error,
    mutateLeaderboard: (votes: IGetVotesForContestApiResponse["data"]) => mutate({ ...(data as IGetVotesForContestApiResponse || {}), data: votes })
  }
};
