
import { formatDuration, intervalToDuration } from "date-fns";
import { useMemo } from "react";
import { map, Observable, timer } from "rxjs";

export const useCountdown = (endTime?: string): Observable<string> => {
  return useMemo(() => timer(0, 1000)
    .pipe(
      map(() => formatDuration(
        intervalToDuration({
          start: Date.now(),
          end: endTime ? new Date(endTime) : Date.now()
        }),
        { format: ['days', 'hours', 'minutes', 'seconds'], delimiter: ' ' }
      ).replace(/ays?|ours?|inutes?|econds?/gi, ''))
    ), [endTime]);
};
