import { Api } from '@helpers/api';
import { IApiPaginateResponse } from '@helpers/api/types';
import {
  CreateVoteOptions,
  IVote,
  IVoteApiResponse,
  IGetVotesForContestApiResponse,
  IGetVotesForAuthorApiResponse,
} from './types';

const VOTES_API_BASE = 'votes';

/* Vote API Helpers */
export const getVotesForContest = async (id: string, page?: Pick<IApiPaginateResponse, 'page' | 'itemsPerPage'>) => {
  const res: IGetVotesForContestApiResponse = await Api.get(`${VOTES_API_BASE}/contest/${id}${page ? `?itemsPage=${page.page}&itemsPerPage=${page.itemsPerPage || 10}` : ''}`);
  return res;
};

export const getVotesForAuthor = async (id: string, page?: Pick<IApiPaginateResponse, 'page' | 'itemsPerPage'>) => {
  const res: IGetVotesForAuthorApiResponse = await Api.get(`${VOTES_API_BASE}/author/${id}${page ? `?itemsPage=${page.page}&itemsPerPage=${page.itemsPerPage || 10}` : ''}`);
  return res;
};

export const createVote = async (data: CreateVoteOptions) => {
  const res: IVoteApiResponse = await Api.post(`${VOTES_API_BASE}/`, data);
  return res;
};

export const updateVote = async (id: string, data: Partial<IVote>) => {
  const res: IVoteApiResponse = await Api.put(`${VOTES_API_BASE}/${id}`, data);
  return res;
};

export const removeVote = async (id: string) => {
  const res: IVoteApiResponse = await Api.delete(`${VOTES_API_BASE}/${id}`);
  return res;
};
