import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { AUTH_URLS } from '@constants';
import { getAuthUser, loadAuthToken } from '@services/user';
import _ from 'lodash';
import useSWR from 'swr';

export const useUser = (opts: { redirect?: boolean; } = { redirect: true }) => {
  const [userIsReady, setUserIsReady] = useState(false);
  const token = loadAuthToken();
  const router = useRouter();

  const fetcher = () => getAuthUser().then(({ data }) => data);

  const { data: user, error, mutate } = useSWR('getAuthUser', fetcher, {
    onErrorRetry: (error) => {
      // Never retry on 404.
      if (error.status === 404) return
    }
  });

  const isAuthUrl = AUTH_URLS.split(' ').some(url => router.asPath.includes(url));
  const redirectUrl = `/auth/login${!isAuthUrl ? '?continue=' + encodeURIComponent(router.asPath) : ''}`;

  const redirectTo = useCallback(
    (path: string) => {
      if (opts.redirect) {
        router.push(path);
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opts.redirect]);

  useEffect(() => {
    // if auth token is not found, redirect to login
    if (!token) {
      if (!isAuthUrl) {
        redirectTo(redirectUrl);
      }
      // if already on login or signup, do nothing
      return setUserIsReady(true);
    }

    // if user has not loaded, wait...
    if (!user) { return; }

    // if user tries to access auth urls after login, redirect to dashboard
    if (isAuthUrl && !router.pathname.includes('dashboard')) {
      redirectTo('/dashboard');
    }

    setUserIsReady(true);
  }, [token, router, isAuthUrl, redirectUrl, user, redirectTo]);

  return {
    user,
    mutateUser: mutate,
    /** Boolean for showing loading screen while fetching and validating user state */
    userIsReady: userIsReady
  };
};
