import { Api } from '@helpers/api';
import { IApiPaginateResponse } from '@helpers/api/types';
import {
  CreateContestOptions,
  IContest,
  IContestApiResponse,
  IContestPublishEligibilityApiResponse,
  IGetContestsApiResponse,
  IGetSingleContestApiResponse
} from './types';

const CONTESTS_API_BASE = 'contests';

/* Contest API Helpers */
export const getAllContests = async (param?: 'author' | 'contestant', page?: Pick<IApiPaginateResponse, 'page' | 'itemsPerPage'>): Promise<IGetContestsApiResponse> => {
  const { page: itemsPage, itemsPerPage } = page || { page: 1, itemsPerPage: 10 };
  let query = {};
  if (param) {
    query = { param, id: 'me' };
  }

  const res = await Api.get(`${CONTESTS_API_BASE}`, { params: { itemsPage, itemsPerPage, ...query } });
  return res;
};

export const getSingleContest = async (id: string) => {
  const res: IGetSingleContestApiResponse = await Api.get(`${CONTESTS_API_BASE}/${id}`);
  return res;
};

export const createContest = async (data: CreateContestOptions) => {
  const res: IContestApiResponse = await Api.post(`${CONTESTS_API_BASE}/`, data);
  return res;
};

export const updateContest = async (id: string, data: Partial<CreateContestOptions>) => {
  const res: IGetSingleContestApiResponse = await Api.put(`${CONTESTS_API_BASE}/${id}`, data);
  return res;
};

export const removeContest = async (id: string) => {
  const res: IContestApiResponse = await Api.delete(`${CONTESTS_API_BASE}/${id}`);
  return res;
};

export const updateContestImage = async (id: string, file: File) => {
  const data = new FormData();
  data.append('image', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const res: IContestApiResponse = await Api.post(`${CONTESTS_API_BASE}/${id}/upload`, data, config);
  return res;
};

export const checkContestPublishEligibility = async (id: string) => {
  const res: IContestPublishEligibilityApiResponse = await Api.get(`${CONTESTS_API_BASE}/${id}/eligibility`)
  return res
} 